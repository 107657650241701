

let bringable_levels = {
	1:"Required",
	2:"Important",
	3:"Useful",
	4:"Optional",
}




let constants = {
	"bringable_levels": bringable_levels
};

export default constants;